<template>
  <div class="login-container">
    <div class="login-container-form">
      <div class="login-container-hello">智慧学习平台</div>
      <a-form-model ref="formRef" :model="form" :rules="rules">
        <a-form-model-item prop="username">
          <a-input v-model="form.username" autocomplete="off" placeholder="请输入账号">
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input-password v-model="form.password" autocomplete="off" placeholder="请输入密码">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSubmit" :loading="loading">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <div class="gtc gc-fff">
        <span class="g-pointer" @click="$router.push('/forget')">忘记密码</span>
      </div>
    </div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/?spm=5176.19720258.J_9220772140.115.2ecc2c4a2ZV2Ut#/Integrated/index" target="_blank">上海助橙教育科技有限公司 沪ICP备2022009884号-3</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: undefined,
        password: undefined,
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    //登录提交
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 200) {
                this.$router.push("/");
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  height: 100vh;
  background: url("~@/assets/imgs/img1.png");
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .login-container-form {
    width: 440px;
    height: 400px;
    padding: 4vh;
    margin-right: 140px;
    background: url("~@/assets/imgs/img2.png");
    background-size: 100% 100%;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
    /deep/ .ant-input {
      height: 35px;
    }

    /deep/ .ant-btn {
      width: 100%;
      height: 45px;
      border-radius: 99px;
    }
    .login-container-hello {
      font-size: 32px;
      color: #fff;
      margin-bottom: 15px;
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 24px;
    text-align: center;
    a {
      color: #fff;
    }
  }
}
</style>
